import gql from 'graphql-tag';

export const createTag = gql`
  mutation createTag($name: String!, $nameEs: String) {
    createTag(name: $name, nameEs: $nameEs) {
      id
      name
      nameEs
    }
  }
`;

export const deleteTag = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id)
  }
`;

export const updateImageStage = gql`
  mutation updateImageStage($ids:[ID!], $production: Boolean!) {
    updateImageStage(ids: $ids, production: $production)
  }
`;

export const editImage = gql`
  mutation editImage($id: ID!, $name: String, $tags: [CategoriesTags], $authorInfo: AuthorInfo, $tags:  [CategoriesTags], $fileName: String, $imageUuid: String) {
    editImage(id: $id, name: $name, tags: $tags, authorInfo: $authorInfo, tags: $tags, fileName: $fileName, imageUuid: $imageUuid) {
      id
      name
      imageUrl
      tags {
        id
        name
        nameEs
        category
        categoryId
      }
      isPremium
      resPhotoAuthor
      resPhotoCredit
      resPhotoRoyaltyFree
      resPhotoSource
      resVecAuthor
      resVecCredit
      resVecRoyaltyFree
      resVecSource
      resTypoAuthor
      resTypoCredit
      resTypoRoyaltyFree
      resTypogSource
    }
  }
`;

export const saveTemplates = gql`
  mutation saveTemplates($templates: [Template]) {
    saveTemplates(templates: $templates)
  }
`;

export const deleteImage = gql`
  mutation deleteContent($imageId: Int!) {
    deleteContent(imageId: $imageId)
  }
`;
